import React, { useEffect, useState } from 'react'
import Container from '../../Components/Container'
import toast, { Toaster } from 'react-hot-toast'

const List = () => {

    const [data, setData] = useState([])
    const D = new Date()
    const [date, setDate] = useState(D.toLocaleDateString())
    // setDate(date.toLocaleDateString())


    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'selectList')
        formdata.append('date', date)
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                setData(data[0])
            })
    }, [])


    const delteHandel = (item) => {
        const formdata = new FormData()
        formdata.append('action', 'deleteList')
        formdata.append('sno', item)
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {

                if (data.status != 200) {
                    toast.error(data.message)
                } else {
                    toast.success(data.message)
                    setTimeout(() => {
                        window.location.reload()
                    }, 500);
                }

            })
    }

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'selectList')
        formdata.append('date', date)
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                setData(data[0])
            })

    }, [date])
    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            <div className='w-full flex items-center justify-start px-4'>
                <h1 className='text-2xl font-bold'>Alınacaklar</h1>
                <span className='w-full flex flex-col items-end justify-end gap-1'>
                    <p className='text-lg font-bold'>
                        {date}
                    </p>
                    <input type='date' value={date} className='' onChange={(e) => { setDate(e.target.value) }}></input>
                </span>
            </div>
            <div className='w-full hidden lg:flex items-start justify-start p-4'>
                <table className="table-fixed  w-full text-left">
                    <thead className="uppercase bg-[#6b7280] text-[#e5e7eb]" >
                        <tr>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Sno</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Ürün resim</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Ürün adı</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Barkode</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Eklenme Tarihi</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true"></td>
                        </tr>
                    </thead>
                    <tbody className="bg-white text-gray-500 " >
                        {
                            data && data.map((item) => {
                                return (
                                    <tr className="py-5">
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.sno}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true"><img className='w-24 aspect-square' src={item.img}></img></td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.name}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.barcode}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.date}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">
                                            <button onClick={() => { delteHandel(item.sno) }} className='w-full flex items-center justify-center p-4 bg-red-500 rounded-md text-white'>Sil</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
            {
                data && data.map((item) => {
                    return (
                        <div className="flex w-full lg:hidden border border-black bg-slate-50 p-2 ">
                            <span className="w-full" contenteditable="true"><img className='w-full' src={item.img}></img></span>
                            <div className='w-full flex flex-col items-center justify-between  p-2'>
                                <span className=" flex items-center justify-start w-full  h-full " contenteditable="true">{item.name}</span>
                                <span className=" flex items-center justify-start w-full  h-full " contenteditable="true">{item.barcode}</span>
                                <span className=" flex items-center justify-start w-full  h-full " contenteditable="true">{item.date}</span>
                                <span className="w-full" contenteditable="true">
                                    <button onClick={() => { delteHandel(item.sno) }} className='w-full flex items-center justify-center p-4 bg-red-500 rounded-md text-white'>Sil</button>
                                </span>
                            </div>
                        </div>
                    )
                })
            }

        </Container>
    )
}

export default List