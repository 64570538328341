import React, { useEffect, useRef } from 'react'
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, NavLink } from 'react-router-dom';
import { GoHome } from "react-icons/go";
import { FaStore } from "react-icons/fa";
import { FaBarcode } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { IoMdNotifications } from "react-icons/io";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoExitOutline } from "react-icons/io5";
import { MdPeople } from "react-icons/md";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import { IoPerson } from "react-icons/io5";
import { MdOutlinePointOfSale } from "react-icons/md";
import { GiBanknote } from "react-icons/gi";
import { FaSitemap } from "react-icons/fa6";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useState } from 'react';
import { FaTrash } from "react-icons/fa";
import { FaBook } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { FaList } from "react-icons/fa";








const Sidebar = () => {

    const [role, setRole] = useState('')
    useEffect(() => {
        const role = window.localStorage.getItem('role')
        setRole(role)


    }, [])

    const LogoutHandel = () => {
        window.localStorage.clear()
        window.location.href = '/'
    }

    const menuRef = useRef(null)
    const items = useRef(null)
    const url = useLocation().pathname
    const menuToggle = () => {
        menuRef.current.classList.toggle('!w-0')
        items.current.classList.toggle('hidden')
    }
    document.addEventListener('click', (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            menuRef.current.classList.add('!w-0')
            items.current.classList.add('hidden')
        }
    })

    document.removeEventListener('click', (e) => {
    })
    useEffect(() => {
        menuRef.current.classList.add('!w-0')
        items.current.classList.add('hidden')
    }, [url])

    return (
        <>

            <div ref={menuRef} className='w-60 z-10 !w-0 fixed  pb-12 transition-all  bg-indigo-600  top-0 left-0 h-screen'>
                <GiHamburgerMenu onClick={() => { menuToggle() }} className='absolute top-2 -right-11 bg-indigo-600 p-1 rounded-r-md  text-white cursor-pointer ' size={44}></GiHamburgerMenu>
                <div ref={items} className='w-full hidden overflow-hidden transition-all flex flex-col h-full items-center justify-between p-4 '>
                    <div className='w-full flex gap-3 whitespace-nowrap flex-col items-start justify-between'>
                        {
                            role === 'admin' ? <NavLink
                                to={'/dashboard/panel'}
                                className={({ isActive }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <GoHome size={24}></GoHome>
                                <span className='ml-4'>Ana Sayfa</span>
                            </NavLink> : <NavLink
                                to={'/dashboard/userPanel'}
                                className={({ isActive }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <GoHome size={24}></GoHome>
                                <span className='ml-4'>Ana Sayfa</span>
                            </NavLink>
                        }
                        {
                            role === 'admin' ? <Menu as='div' className='w-full'>
                                <MenuButton className={({ isActive }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }>
                                    <MdOutlinePointOfSale size={24}></MdOutlinePointOfSale>
                                    <span className='ml-4'>Satış Ayarları</span>
                                </MenuButton>
                                <MenuItems className='w-full bg-black/20'>
                                    <NavLink
                                        to="/dashboard/sales"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaRegMoneyBillAlt size={24}></FaRegMoneyBillAlt>
                                        <span className='ml-4'>Satış</span>
                                    </NavLink>
                                    <NavLink
                                        to="/dashboard/return"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <GiBanknote size={24} />
                                        <span className='ml-4'>İade</span>
                                    </NavLink>
                                    <NavLink
                                        to="/dashboard/casualty"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaTrash size={18} />
                                        <span className='ml-4'>Zayiyat</span>
                                    </NavLink>
                                    <NavLink
                                        to="/dashboard/history"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaHistory size={18} />
                                        <span className='ml-4'>Geçmiş</span>
                                    </NavLink>
                                    <NavLink
                                        to="/dashboard/tick"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaBook size={18} />
                                        <span className='ml-4'>Veresiye</span>
                                    </NavLink>
                                    <NavLink
                                        to="/dashboard/barcode"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaBarcode size={24} />
                                        <span className='ml-4'>Barkod Oluştur</span>
                                    </NavLink>
                                </MenuItems>

                            </Menu> : null
                        }
                        <Menu as='div' className='w-full'>

                            <MenuButton className={({ isActive }) =>
                                isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                            }>
                                <MdOutlineProductionQuantityLimits size={24}></MdOutlineProductionQuantityLimits>
                                <span className='ml-4'>Ürün Ayarları</span>
                            </MenuButton>

                            <MenuItems className='w-full bg-black/20 gap-4'>
                                {
                                    role === 'admin' ?
                                        <NavLink
                                            to="/dashboard/products"
                                            className={({ isActive, isPending }) =>
                                                isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                            }
                                        >
                                            <MdOutlineProductionQuantityLimits size={24}></MdOutlineProductionQuantityLimits>
                                            <span className='ml-4'>Ürünler</span>
                                        </NavLink> : null
                                }
                                {
                                    role === 'admin' ?
                                        <NavLink
                                            to="/dashboard/productsHistory"
                                            className={({ isActive, isPending }) =>
                                                isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                            }
                                        >
                                            <FaHistory size={24} />
                                            <span className='ml-4'>Ürün Geçmişi</span>
                                        </NavLink> : null
                                }
                                {
                                    role === 'admin' ?
                                        <NavLink
                                            to="/dashboard/list"
                                            className={({ isActive, isPending }) =>
                                                isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                            }
                                        >
                                            <FaList size={24} />
                                            <span className='ml-4'>Alınacaklar</span>
                                        </NavLink> : null
                                }
                            </MenuItems>
                        </Menu>
                        {
                            role === 'admin' ?
                                <NavLink
                                    to="/dashboard/categories"
                                    className={({ isActive, isPending }) =>
                                        isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                    }
                                >
                                    <BiSolidCategoryAlt size={24}></BiSolidCategoryAlt>
                                    <span className='ml-4'>Kategori Ayarları</span>
                                </NavLink> : null
                        }
                        {
                            role == 'admin' ? <Menu as='div' className='w-full'>
                                <MenuButton className={({ isActive }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }>
                                    <MdOutlinePointOfSale size={24}></MdOutlinePointOfSale>
                                    <span className='ml-4'>Depo Ayarları</span>
                                </MenuButton>
                                <MenuItems className='w-full bg-black/20'>
                                    <NavLink
                                        to="/dashboard/store"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaStore size={24}></FaStore>
                                        <span className='ml-4'>Depo Ayarları</span>
                                    </NavLink>
                                    <NavLink
                                        to="/dashboard/storeDetail"
                                        className={({ isActive, isPending }) =>
                                            isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                        }
                                    >
                                        <FaSitemap size={24}></FaSitemap>
                                        <span className='ml-4'>Depo İçerik</span>
                                    </NavLink>
                                </MenuItems>

                            </Menu> : null
                        }

                        <Menu>
                            <MenuButton className={({ isActive }) =>
                                isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                            }>
                                <TbReport size={24}></TbReport>
                                <span className='ml-4'>Raporlamalar</span>
                            </MenuButton>
                            <MenuItems className='w-full bg-black/20'>
                            {
                            role == 'admin' ? <NavLink
                                to="/dashboard/report"
                                className={({ isActive, isPending }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <TbReport size={24}></TbReport>
                                <span className='ml-4'>Raporlama</span>
                            </NavLink> : null
                        }
                        {
                            role == 'admin' ? <NavLink
                                to="/dashboard/statistics"
                                className={({ isActive, isPending }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <TbReport size={24}></TbReport>
                                <span className='ml-4'>İstatislikler</span>
                            </NavLink> : null
                        }
                            </MenuItems>
                        </Menu>

                        
                        {
                            role == 'admin' ? <NavLink
                                to="/dashboard/notification"
                                className={({ isActive, isPending }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <IoMdNotifications size={24} />
                                <span className='ml-4'>Bildirim Ayarları</span>
                            </NavLink> : null
                        }

                        {
                            role == 'admin' ? <NavLink
                                to="/dashboard/users"
                                className={({ isActive, isPending }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <MdPeople size={24}></MdPeople >
                                <span className='ml-4'>Personel Ayarları</span>
                            </NavLink> : null
                        }
                        {
                            role == 'admin' ? <NavLink
                                to="/dashboard/profile"
                                className={({ isActive, isPending }) =>
                                    isActive ? 'p-4 text-white w-full bg-indigo-400 rounded-md flex items-center justify-start ' : 'p-4 text-white w-full hover:bg-indigo-400 rounded-md flex items-center justify-start '
                                }
                            >
                                <IoPerson size={24}></IoPerson>
                                <span className='ml-4'>Profil Ayarları</span>
                            </NavLink>
                                : null
                        }



                    </div>
                    <button onClick={() => LogoutHandel()} className='p-4 text-white w-full bg-red-600  rounded-md flex items-center justify-start '>
                        <IoExitOutline size={24}></IoExitOutline>
                        <span className='ml-4'>Çıkış Yap</span>
                    </button>
                </div>

            </div>

        </>
    )
}

export default Sidebar