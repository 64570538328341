
import Container from '../../Components/Container'
import { FaCamera } from "react-icons/fa6";
import React, { useEffect, useRef, useState } from 'react';
import { BarcodeScanner, useTorch } from "react-barcode-scanner"
import "react-barcode-scanner/polyfill"
import SalesCard from '../../Components/SalesCard';
import toast, { Toaster } from 'react-hot-toast';



const Sales = () => {



    const ref = React.useRef(null)
    const ref2 = React.useRef(null)
    const ref3 = React.useRef(null)
    const ref4 = React.useRef(null)
    const barcodeText = useRef()
    const sellBtn = useRef(null)
    const [count, setCount] = useState(1)
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState(false)
    const [barcode, setBarcode] = useState('')
    const [basket, setBasket] = useState(window.localStorage.getItem('basket') ? JSON.parse(window.localStorage.getItem('basket')) : [])
    const [b2, setB2] = useState(window.localStorage.getItem('basket2') ? JSON.parse(window.localStorage.getItem('basket2')) : [])
    const [b3, setB3] = useState(window.localStorage.getItem('basket3') ? JSON.parse(window.localStorage.getItem('basket3')) : [])
    const [b4, setB4] = useState(window.localStorage.getItem('basket4') ? JSON.parse(window.localStorage.getItem('basket4')) : [])
    const [selectedBasket, setSelectedBasket] = useState(0)
    const [total, setTotal] = useState(1)
    const [isSupportTorch, , onTorchSwitch] = useTorch()
    const [alert, setAlert] = useState()
    const [type, setType] = useState(false)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [discount, setDiscount] = useState(0)
    const [discount2, setDiscount2] = useState(0)
    const [discount3, setDiscount3] = useState(0)
    const [discount4, setDiscount4] = useState(0)
    const [per, setPer] = useState(0)
    const [per2, setPer2] = useState(0)
    const [per3, setPer3] = useState(0)
    const [per4, setPer4] = useState(0)

    const toggleHandeler = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }
    const modalHandel = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setModal(false)
        }
    }

    const sellHandel = () => {
        setModal(true)
    }

    const totalHandel = () => {

        switch (selectedBasket) {
            case 0:
                if (type == true) {

                    let total = 0
                    basket.map((item) => {
                        total += item.price * item.count
                    })

                    let d = (total * per) / 100

                    return total - d

                } else {
                    let total = 0
                    basket.map((item) => {
                        total += item.price * item.count
                    })
                    return total - discount
                }

            case 1:

                if (type == true) {

                    let total = 0
                    b2.map((item) => {
                        total += item.price * item.count
                    })

                    let d = (total * per2) / 100

                    return total - d

                } else {
                    let total = 0
                    b2.map((item) => {
                        total += item.price * item.count
                    })
                    return total - discount2
                }
            case 2:

                if (type == true) {

                    let total = 0
                    b3.map((item) => {
                        total += item.price * item.count
                    })

                    let d = (total * per3) / 100

                    return total - d

                } else {
                    let total = 0
                    b3.map((item) => {
                        total += item.price * item.count
                    })
                    return total - discount3
                }
            case 3:

                if (type == true) {

                    let total = 0
                    b4.map((item) => {
                        total += item.price * item.count
                    })

                    let d = (total * per4) / 100

                    return total - d

                } else {
                    let total = 0
                    b4.map((item) => {
                        total += item.price * item.count
                    })
                    return total - discount4
                }


        }

    }

    const fetchHandel = () => {


        setLoading(true)
        const formdata = new FormData()
        formdata.append('action', 'sell')
        formdata.append('total', totalHandel())
        { selectedBasket == 0 && formdata.append('discount', discount / basket.length) }
        { selectedBasket == 1 && formdata.append('discount', discount2 / b2.length) }
        { selectedBasket == 2 && formdata.append('discount', discount3 / b3.length) }
        { selectedBasket == 3 && formdata.append('discount', discount4 / b4.length) }
        formdata.append('user', window.localStorage.getItem('user') ? window.localStorage.getItem('user') : 'Anonim')
        formdata.append('date', new Date().toLocaleDateString())
        formdata.append('time', new Date().toLocaleTimeString())
        { selectedBasket == 0 && formdata.append('basket', JSON.stringify(basket)) }
        { selectedBasket == 1 && formdata.append('basket', JSON.stringify(b2)) }
        { selectedBasket == 2 && formdata.append('basket', JSON.stringify(b3)) }
        { selectedBasket == 3 && formdata.append('basket', JSON.stringify(b4)) }
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(data => {
            if (data.status != 400) {
                setLoading(false)
                toast.success('Satış Başarılı')
                setAlert(data.alert)
                setModal(false)
                setTotal(0)
                setDiscount(0)
                setBarcode('')
                switch (selectedBasket) {
                    case 0:
                        setBasket([])
                        break;

                    case 1:
                        setB2([])
                        break;

                    case 2:
                        setB3([])
                        break;

                    case 3:
                        setB4([])
                        break;
                }

            } else {
                setLoading(false)
                toast.error('Satış Başarısız')
            }
        })

    }
    const onCapture = (detected) => {

        switch (selectedBasket) {

            case 0:
                if (detected) {
                    const formdata = new FormData()
                    formdata.append('action', 'findByBarcode')
                    formdata.append('barcode', detected.rawValue)
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 400) {
                            try {

                                const itemCount = basket.filter((item) => item.barcode === detected.rawValue).length
                                if (itemCount > 0) {
                                    const index = basket.findIndex((item) => item.barcode === detected.rawValue)
                                    if (count > 1) {
                                        basket[index].count = basket[index].count + parseInt(count)
                                        setTotal((prev) => prev + parseInt(basket[index].price) * parseInt(count))
                                    } else {
                                        basket[index].count += 1
                                        setTotal((prev) => prev + parseInt(basket[index].price))
                                    }
                                    setBasket([...basket])
                                    setOpen(false)
                                    setBarcode(detected.rawValue)

                                } else {
                                    const p = parseInt(data.data.price)
                                    setBasket([...basket, {
                                        barcode: detected.rawValue,
                                        name: data.data.name,
                                        image: data.data.image,
                                        cost: data.data.cost,
                                        count: count,
                                        price: p,
                                        store: data.data.store,
                                        category: data.data.category
                                    }])
                                    setTotal(total + p)
                                }

                                setOpen(false)

                                setBarcode(detected.rawValue)
                                ref.current.focus()
                            } catch (error) {
                                console.log(error)
                            }
                        } else {
                            const role = window.localStorage.getItem('role')
                            if (role === 'admin') {
                                window.location.href = '/dashboard/addProduct'
                            } else {
                                toast.error('Ürün Bulunamadı')

                            }
                        }
                    })
                }

                break;

            case 1:
                if (detected) {
                    const formdata = new FormData()
                    formdata.append('action', 'findByBarcode')
                    formdata.append('barcode', detected.rawValue)
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 400) {
                            try {

                                const itemCount = b2.filter((item) => item.barcode === detected.rawValue).length
                                if (itemCount > 0) {
                                    const index = b2.findIndex((item) => item.barcode === detected.rawValue)
                                    if (count > 1) {
                                        b2[index].count = b2[index].count + parseInt(count)
                                        setTotal((prev) => prev + parseInt(b2[index].price) * parseInt(count))
                                    } else {
                                        b2[index].count += 1
                                        setTotal((prev) => prev + parseInt(b2[index].price))
                                    }
                                    setB2([...b2])
                                    setOpen(false)
                                    setBarcode(detected.rawValue)

                                } else {
                                    const p = parseInt(data.data.price)
                                    setB2([...b2, {
                                        barcode: detected.rawValue,
                                        name: data.data.name,
                                        image: data.data.image,
                                        cost: data.data.cost,
                                        count: count,
                                        price: p,
                                        store: data.data.store,
                                        category: data.data.category
                                    }])
                                    setTotal(total + p)
                                }

                                setOpen(false)

                                setBarcode(detected.rawValue)
                                ref2.current.focus()
                            } catch (error) {
                                console.log(error)
                            }
                        } else {
                            const role = window.localStorage.getItem('role')
                            if (role === 'admin') {
                                window.location.href = '/dashboard/addProduct'
                            } else {
                                toast.error('Ürün Bulunamadı')

                            }
                        }
                    })
                }

                break;

            case 2:
                if (detected) {
                    const formdata = new FormData()
                    formdata.append('action', 'findByBarcode')
                    formdata.append('barcode', detected.rawValue)
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 400) {
                            try {

                                const itemCount = b3.filter((item) => item.barcode === detected.rawValue).length
                                if (itemCount > 0) {
                                    const index = b3.findIndex((item) => item.barcode === detected.rawValue)
                                    if (count > 1) {
                                        b3[index].count = b3[index].count + parseInt(count)
                                        setTotal((prev) => prev + parseInt(b3[index].price) * parseInt(count))
                                    } else {
                                        b3[index].count += 1
                                        setTotal((prev) => prev + parseInt(b3[index].price))
                                    }
                                    setB3([...b2])
                                    setOpen(false)
                                    setBarcode(detected.rawValue)

                                } else {
                                    const p = parseInt(data.data.price)
                                    setB3([...b3, {
                                        barcode: detected.rawValue,
                                        name: data.data.name,
                                        image: data.data.image,
                                        cost: data.data.cost,
                                        count: count,
                                        price: p,
                                        store: data.data.store,
                                        category: data.data.category
                                    }])
                                    setTotal(total + p)
                                }

                                setOpen(false)

                                setBarcode(detected.rawValue)
                                ref3.current.focus()
                            } catch (error) {
                                console.log(error)
                            }
                        } else {
                            const role = window.localStorage.getItem('role')
                            if (role === 'admin') {
                                window.location.href = '/dashboard/addProduct'
                            } else {
                                toast.error('Ürün Bulunamadı')

                            }
                        }
                    })
                }

                break;


            case 3:
                if (detected) {
                    const formdata = new FormData()
                    formdata.append('action', 'findByBarcode')
                    formdata.append('barcode', detected.rawValue)
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 400) {
                            try {

                                const itemCount = b4.filter((item) => item.barcode === detected.rawValue).length
                                if (itemCount > 0) {
                                    const index = b4.findIndex((item) => item.barcode === detected.rawValue)
                                    if (count > 1) {
                                        b4[index].count = b4[index].count + parseInt(count)
                                        setTotal((prev) => prev + parseInt(b4[index].price) * parseInt(count))
                                    } else {
                                        b4[index].count += 1
                                        setTotal((prev) => prev + parseInt(b4[index].price))
                                    }
                                    setB4([...b4])
                                    setOpen(false)
                                    setBarcode(detected.rawValue)

                                } else {
                                    const p = parseInt(data.data.price)
                                    setB4([...b4, {
                                        barcode: detected.rawValue,
                                        name: data.data.name,
                                        image: data.data.image,
                                        cost: data.data.cost,
                                        count: count,
                                        price: p,
                                        store: data.data.store,
                                        category: data.data.category
                                    }])
                                    setTotal(total + p)
                                }

                                setOpen(false)

                                setBarcode(detected.rawValue)
                                ref4.current.focus()
                            } catch (error) {
                                console.log(error)
                            }
                        } else {
                            const role = window.localStorage.getItem('role')
                            if (role === 'admin') {
                                window.location.href = '/dashboard/addProduct'
                            } else {
                                toast.error('Ürün Bulunamadı')

                            }
                        }
                    })
                }

                break;

        }

    }

    const handleProductNotFound = () => {
        const role = window.localStorage.getItem('role');
        if (role === 'admin') {
            const c = window.confirm('Ürün Bulunamadı, Eklemek ister misiniz?');
            if (c) {
                window.location.href = '/dashboard/addProduct';
            }
        } else {
            toast.error('Ürün Bulunamadı');
        }
    };

    const basketHandel=(id)=>{
        setCount(1)
        setSelectedBasket(id)
    }

    const keyHandel = (e) => {
        switch (selectedBasket) {
            case 0:
                if (e.key === 'Enter') {
                    const formdata = new FormData();
                    formdata.append('action', 'findByBarcode');
                    formdata.append('barcode', barcode);
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 404) {
                            const itemCount = basket.filter((item) => item.barcode === barcode).length;
                            if (itemCount > 0) {
                                const index = basket.findIndex((item) => item.barcode === barcode);
                                if (count > 1) {
                                    basket[index].count = basket[index].count + parseInt(count);
                                    setTotal((prev) => prev + parseInt(basket[index].price) * parseInt(count));
                                } else {
                                    basket[index].count += 1;
                                    setTotal((prev) => prev + parseInt(basket[index].price));
                                }
                                setBasket([...basket]); // Sadece 1. sepet için
                            } else {
                                const p = parseInt(data.price);
                                setBasket([...basket, {
                                    image: data.data.image,
                                    name: data.data.name,
                                    cost: data.data.cost,
                                    barcode,
                                    count: count,
                                    price: parseInt(data.data.price),
                                    store: data.data.store,
                                    category: data.data.category
                                }]);
                                setTotal(total + p);
                            }
                            setOpen(false);
                            setBarcode("");
                        } else {
                            handleProductNotFound();
                        }
                    });
                }
                break;

            case 1:
                if (e.key === 'Enter') {
                    const formdata = new FormData();
                    formdata.append('action', 'findByBarcode');
                    formdata.append('barcode', barcode);
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 404) {
                            const itemCount = b2.filter((item) => item.barcode === barcode).length;
                            if (itemCount > 0) {
                                const index = b2.findIndex((item) => item.barcode === barcode);
                                if (count > 1) {
                                    b2[index].count = b2[index].count + parseInt(count);
                                    setTotal((prev) => prev + parseInt(b2[index].price) * parseInt(count));
                                } else {
                                    b2[index].count += 1;
                                    setTotal((prev) => prev + parseInt(b2[index].price));
                                }
                                setB2([...b2]); // 2. sepet için
                            } else {
                                const p = parseInt(data.price);
                                setB2([...b2, {
                                    image: data.data.image,
                                    name: data.data.name,
                                    cost: data.data.cost,
                                    barcode,
                                    count: count,
                                    price: parseInt(data.data.price),
                                    store: data.data.store,
                                    category: data.data.category
                                }]);
                                setTotal(total + p);
                            }
                            setOpen(false);
                            setBarcode("");
                        } else {
                            handleProductNotFound();
                        }
                    });
                }
                break;

            case 2:
                if (e.key === 'Enter') {
                    const formdata = new FormData();
                    formdata.append('action', 'findByBarcode');
                    formdata.append('barcode', barcode);
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 404) {
                            const itemCount = b3.filter((item) => item.barcode === barcode).length;
                            if (itemCount > 0) {
                                const index = b3.findIndex((item) => item.barcode === barcode);
                                if (count > 1) {
                                    b3[index].count = b3[index].count + parseInt(count);
                                    setTotal((prev) => prev + parseInt(b3[index].price) * parseInt(count));
                                } else {
                                    b3[index].count += 1;
                                    setTotal((prev) => prev + parseInt(b3[index].price));
                                }
                                setB3([...b3]); // 3. sepet için
                            } else {
                                const p = parseInt(data.price);
                                setB3([...b3, {
                                    image: data.data.image,
                                    name: data.data.name,
                                    cost: data.data.cost,
                                    barcode,
                                    count: count,
                                    price: parseInt(data.data.price),
                                    store: data.data.store,
                                    category: data.data.category
                                }]);
                                setTotal(total + p);
                            }
                            setOpen(false);
                            setBarcode("");
                        } else {
                            handleProductNotFound();
                        }
                    });
                }
                break;

            case 3:
                if (e.key === 'Enter') {
                    const formdata = new FormData();
                    formdata.append('action', 'findByBarcode');
                    formdata.append('barcode', barcode);
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 404) {
                            const itemCount = b4.filter((item) => item.barcode === barcode).length;
                            if (itemCount > 0) {
                                const index = b4.findIndex((item) => item.barcode === barcode);
                                if (count > 1) {
                                    b4[index].count = b4[index].count + parseInt(count);
                                    setTotal((prev) => prev + parseInt(b4[index].price) * parseInt(count));
                                } else {
                                    b4[index].count += 1;
                                    setTotal((prev) => prev + parseInt(b4[index].price));
                                }
                                setB4([...b4]); // 4. sepet için
                            } else {
                                const p = parseInt(data.price);
                                setB4([...b4, {
                                    image: data.data.image,
                                    name: data.data.name,
                                    cost: data.data.cost,
                                    barcode,
                                    count: count,
                                    price: parseInt(data.data.price),
                                    store: data.data.store,
                                    category: data.data.category
                                }]);
                                setTotal(total + p);
                            }
                            setOpen(false);
                            setBarcode("");
                        } else {
                            handleProductNotFound();
                        }
                    });
                }
                break;
        }
    };


    const clearBasket = (func) => {
        func([])
        setBarcode('')

    }

    useEffect(() => {

        switch (selectedBasket) {
            case 0:
                if (modal) {
                    if (discount > totalHandel()) {
                        toast.error('İndirim sepet tutarından fazla olamaz')
                        sellBtn.current.classList.add('hidden')
                    } else {
                        sellBtn.current.classList.remove('hidden')
                    }
                }
                break;

            case 1:
                if (modal) {
                    if (discount2 > totalHandel()) {
                        toast.error('İndirim sepet tutarından fazla olamaz')
                        sellBtn.current.classList.add('hidden')
                    } else {
                        sellBtn.current.classList.remove('hidden')
                    }
                }
                break;

            case 2:
                if (modal) {
                    if (discount3 > totalHandel()) {
                        toast.error('İndirim sepet tutarından fazla olamaz')
                        sellBtn.current.classList.add('hidden')
                    } else {
                        sellBtn.current.classList.remove('hidden')
                    }
                }
                break;

            case 3:
                if (modal) {
                    if (discount4 > totalHandel()) {
                        toast.error('İndirim sepet tutarından fazla olamaz')
                        sellBtn.current.classList.add('hidden')
                    } else {
                        sellBtn.current.classList.remove('hidden')
                    }
                }
                break;
        }


    }, [discount, discount2, discount3, discount4])

    useEffect(() => {
        setCount(1)
        switch (selectedBasket) {
            case 0:
                window.localStorage.setItem('basket', JSON.stringify(basket))
                break;
            case 1:
                window.localStorage.setItem('basket2', JSON.stringify(b2))
                break;
            case 2:
                window.localStorage.setItem('basket3', JSON.stringify(b3))
                break;
            case 3:
                window.localStorage.setItem('basket4', JSON.stringify(b4))
                break;
        }

    }, [basket, b2, b3, b4])



    const options = {

        formats: [
            'ean_13',
            'code_128',
            'code_39',
            'code_93',
            'codabar',
            'ean_8',
            'itf',
            'qr_code',
            'upc_a',
            'upc_e',
        ]
    }

    const basketSet = (item) => {
        
        console.log(item)

        switch (selectedBasket) {
            case 0:
                const formdata = new FormData();
                    formdata.append('action', 'findByBarcode');
                    formdata.append('barcode', item.barcode);
                    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
                        method: 'POST',
                        body: formdata
                    }).then(res => res.json()).then(data => {
                        if (data.status != 404) {
                            const itemCount = basket.filter((item) => item.barcode === barcode).length;
                            if (itemCount > 0) {
                                const index = basket.findIndex((item) => item.barcode === barcode);
                                if (count > 1) {
                                    basket[index].count = basket[index].count + parseInt(count);
                                    setTotal((prev) => prev + parseInt(basket[index].price) * parseInt(count));
                                } else {
                                    basket[index].count += 1;
                                    setTotal((prev) => prev + parseInt(basket[index].price));
                                }
                                setBasket([...basket]); // 3. sepet için
                            } else {
                                const p = parseInt(data.price);
                                setBasket([...basket, {
                                    image: data.data.image,
                                    name: data.data.name,
                                    cost: data.data.cost,
                                    barcode,
                                    count: count,
                                    price: parseInt(data.data.price),
                                    store: data.data.store,
                                    category: data.data.category
                                }]);
                                setTotal(total + p);
                            }
                            setOpen(false);
                            setBarcode("");
                        } else {
                            handleProductNotFound();
                        }
                    });

                break;
        }


    }



    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'list')
        fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                setList(data)
            })
    }, [])

    return (
        <Container>
            <div className='w-full flex h-auto gap-2'>
                {
                    alert != null ?
                        <div onClick={(e) => { e.target.remove() }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                            <div className='bg-white p-4 text-red-500 text-2xl capitalize rounded-md flex flex-col'>
                                {
                                    alert
                                }
                            </div>
                        </div> : null
                }
                <Toaster position='top-center'></Toaster>
                {
                    modal && selectedBasket == 0 && <div onClick={(e) => { modalHandel(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                        <div className='bg-white rounded-md lg p-4 lg:h-full w-full overflow-y-scroll max-h-screen  gap-12 flex items-start justify-start flex-col'>
                            {
                                basket && basket.map((item) => {
                                    return (
                                        <span key={item.barcode} className='w-full flex items-center  justify-between'>
                                            <p>
                                                {
                                                    item.name
                                                }
                                            </p>
                                            <p>
                                                {
                                                    item.barcode
                                                }
                                            </p>
                                            <span className='flex flex-col items-center justify-center gap-1'>
                                                <p>
                                                    {
                                                        parseInt(item.count)
                                                    }
                                                    adet
                                                </p>
                                            </span>
                                        </span>
                                    )
                                })
                            }
                            <span className={'w-full flex items-center justify-end text-black'}>
                                Toplam : {totalHandel()} TL
                            </span>

                            <div className='w-full flex flex-col items-center justify-center gap-2'>
                                <span className='w-full flex items-center justify-center gap-4'>
                                    {
                                        !type && <input value={discount} min={0} onChange={(e) => { setDiscount(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    {
                                        type && <input min={0} max={100} value={per} onChange={(e) => { setPer(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    <button onClick={() => { setType(!type) }} className={'bg-green-600 w-full tracking-wide text-white rounded-md px-4 py-2 '}>
                                        {
                                            type == true ? 'Yüzdesel' : 'Rakamsal'
                                        }
                                    </button>
                                </span>
                                <button disabled={loading} onClick={() => { fetchHandel() }} ref={sellBtn} className={'bg-green-600 disabled:bg-gray-300 w-full tracking-wide text-white rounded-md p-4 '}>Tamamla</button>

                            </div>
                        </div>
                    </div>
                }

                {
                    modal && selectedBasket == 1 && <div onClick={(e) => { modalHandel(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                        <div className='bg-white rounded-md lg p-4 lg:h-full w-full overflow-y-scroll max-h-screen  gap-12 flex items-start justify-start flex-col'>
                            {
                                b2 && b2.map((item) => {
                                    return (
                                        <span key={item.barcode} className='w-full flex items-center  justify-between'>
                                            <p>
                                                {
                                                    item.name
                                                }
                                            </p>
                                            <p>
                                                {
                                                    item.barcode
                                                }
                                            </p>
                                            <span className='flex flex-col items-center justify-center gap-1'>
                                                <p>
                                                    {
                                                        parseInt(item.count)
                                                    }
                                                    adet
                                                </p>
                                            </span>
                                        </span>
                                    )
                                })
                            }
                            <span className={'w-full flex items-center justify-end text-black'}>
                                Toplam : {totalHandel()} TL
                            </span>

                            <div className='w-full flex flex-col items-center justify-center gap-2'>
                                <span className='w-full flex items-center justify-center gap-4'>
                                    {
                                        !type && <input value={discount2} min={0} onChange={(e) => { setDiscount2(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    {
                                        type && <input min={0} max={100} value={per2} onChange={(e) => { setPer2(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    <button onClick={() => { setType(!type) }} className={'bg-green-600 w-full tracking-wide text-white rounded-md px-4 py-2 '}>
                                        {
                                            type == true ? 'Yüzdesel' : 'Rakamsal'
                                        }
                                    </button>
                                </span>
                                <button disabled={loading} onClick={() => { fetchHandel() }} ref={sellBtn} className={'bg-green-600 disabled:bg-gray-300 w-full tracking-wide text-white rounded-md p-4 '}>Tamamla</button>

                            </div>
                        </div>
                    </div>
                }
                {
                    modal && selectedBasket == 2 && <div onClick={(e) => { modalHandel(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                        <div className='bg-white rounded-md lg p-4 lg:h-full w-full overflow-y-scroll max-h-screen  gap-12 flex items-start justify-start flex-col'>
                            {
                                b3 && b3.map((item) => {
                                    return (
                                        <span key={item.barcode} className='w-full flex items-center  justify-between'>
                                            <p>
                                                {
                                                    item.name
                                                }
                                            </p>
                                            <p>
                                                {
                                                    item.barcode
                                                }
                                            </p>
                                            <span className='flex flex-col items-center justify-center gap-1'>
                                                <p>
                                                    {
                                                        parseInt(item.count)
                                                    }
                                                    adet
                                                </p>
                                            </span>
                                        </span>
                                    )
                                })
                            }
                            <span className={'w-full flex items-center justify-end text-black'}>
                                Toplam : {totalHandel()} TL
                            </span>

                            <div className='w-full flex flex-col items-center justify-center gap-2'>
                                <span className='w-full flex items-center justify-center gap-4'>
                                    {
                                        !type && <input value={discount3} min={0} onChange={(e) => { setDiscount3(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    {
                                        type && <input min={0} max={100} value={per3} onChange={(e) => { setPer3(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    <button onClick={() => { setType(!type) }} className={'bg-green-600 w-full tracking-wide text-white rounded-md px-4 py-2 '}>
                                        {
                                            type == true ? 'Yüzdesel' : 'Rakamsal'
                                        }
                                    </button>
                                </span>
                                <button disabled={loading} onClick={() => { fetchHandel() }} ref={sellBtn} className={'bg-green-600 disabled:bg-gray-300 w-full tracking-wide text-white rounded-md p-4 '}>Tamamla</button>

                            </div>
                        </div>
                    </div>
                }
                {
                    modal && selectedBasket == 3 && <div onClick={(e) => { modalHandel(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                        <div className='bg-white rounded-md lg p-4 lg:h-full w-full overflow-y-scroll max-h-screen  gap-12 flex items-start justify-start flex-col'>
                            {
                                b4 && b4.map((item) => {
                                    return (
                                        <span key={item.barcode} className='w-full flex items-center  justify-between'>
                                            <p>
                                                {
                                                    item.name
                                                }
                                            </p>
                                            <p>
                                                {
                                                    item.barcode
                                                }
                                            </p>
                                            <span className='flex flex-col items-center justify-center gap-1'>
                                                <p>
                                                    {
                                                        parseInt(item.count)
                                                    }
                                                    adet
                                                </p>
                                            </span>
                                        </span>
                                    )
                                })
                            }
                            <span className={'w-full flex items-center justify-end text-black'}>
                                Toplam : {totalHandel()} TL
                            </span>

                            <div className='w-full flex flex-col items-center justify-center gap-2'>
                                <span className='w-full flex items-center justify-center gap-4'>
                                    {
                                        !type && <input value={discount4} min={0} onChange={(e) => { setDiscount4(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    {
                                        type && <input min={0} max={100} value={per4} onChange={(e) => { setPer4(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                                    }
                                    <button onClick={() => { setType(!type) }} className={'bg-green-600 w-full tracking-wide text-white rounded-md px-4 py-2 '}>
                                        {
                                            type == true ? 'Yüzdesel' : 'Rakamsal'
                                        }
                                    </button>
                                </span>
                                <button disabled={loading} onClick={() => { fetchHandel() }} ref={sellBtn} className={'bg-green-600 disabled:bg-gray-300 w-full tracking-wide text-white rounded-md p-4 '}>Tamamla</button>

                            </div>
                        </div>
                    </div>
                }


                {
                    open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                        <div className='bg-white rounded-md lg p-4 lg:h-full w-full  gap-6 flex items-start justify-start flex-col'>
                            <BarcodeScanner options={options} onCapture={onCapture} />
                            <div className='w-full flex items-center gap-2 justify-between'>
                                <input type='number' onChange={(e) => { setCount(e.target.value) }} value={count} placeholder='Adet' className='w-full p-2 border border-gray-300 rounded-md' />
                                {isSupportTorch ? (
                                    <button className='bg-yellow-400 whitespace-nowrap text-black px-4 py-2 rounded-md' onClick={onTorchSwitch}>El Feneri</button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                }
                <div className='w-full flex flex-col items-start min-h-screen h-auto justify-start relative gap-4'>
                    <div className='w-full flex-col flex items-center gap-2 justify-between'>
                        <span className='w-full flex items-center justify-center gap-4'>
                            <input type='text' ref={barcodeText} onKeyDown={(e) => { keyHandel(e) }} value={barcode} onChange={(e) => { setBarcode(e.target.value) }} placeholder='Barkod giriniz' className='w-full p-2 border border-gray-300 rounded-md' />
                            <input type='text' value={count} onChange={(e) => { setCount(e.target.value) }} className='w-20 p-2 border border-gray-300 rounded-md' />
                            {
                                selectedBasket == 0 && basket.length > 0 && <button onClick={() => { clearBasket(setBasket) }} className='bg-red-500 whitespace-nowrap text-white rounded-md p-2'>Sepeti Temizle</button>
                            }
                            {
                                selectedBasket == 1 && b2.length > 0 && <button onClick={() => { clearBasket(setB2) }} className='bg-red-500 whitespace-nowrap text-white rounded-md p-2'>Sepeti Temizle</button>
                            }
                            {
                                selectedBasket == 2 && b3.length > 0 && <button onClick={() => { clearBasket(setB3) }} className='bg-red-500 whitespace-nowrap text-white rounded-md p-2'>Sepeti Temizle</button>
                            }
                            {
                                selectedBasket == 3 && b4.length > 0 && <button onClick={() => { clearBasket(setB4) }} className='bg-red-500 whitespace-nowrap text-white rounded-md p-2'>Sepeti Temizle</button>
                            }
                            <button onClick={() => { setOpen(true) }} className='bg-indigo-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
                                <FaCamera />
                                <p className='w-0 group-hover:w-32 overflow-hidden transition-all'>
                                    Kamera ile oku
                                </p>
                            </button>
                        </span>

                        <select onChange={(e) => { setBarcode(e.target.value) }} className='p-2 border w-full  border-gray-300 rounded-md'>
                            <option>
                                Hızlı ürün seçiniz
                            </option>
                            {
                                list && list.map((item) => {
                                    return (
                                        <option value={item.barcode} key={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <div className='w-full flex items-center justify-center gap-4'>
                            <button className={selectedBasket == 0 ? 'bg-indigo-600 p-2 w-full text-white rounded-md' : 'bg-slate-400 p-2 w-full text-white rounded-md'} onClick={() => {basketHandel(0) }} >Sepet 1</button>
                            <button className={selectedBasket == 1 ? 'bg-indigo-600 p-2 w-full text-white rounded-md' : 'bg-slate-400 p-2 w-full text-white rounded-md'} onClick={() => {basketHandel(1) }} >Sepet 2</button>
                            <button className={selectedBasket == 2 ? 'bg-indigo-600 p-2 w-full text-white rounded-md' : 'bg-slate-400 p-2 w-full text-white rounded-md'} onClick={() => {basketHandel(2) }} >Sepet 3</button>
                            <button className={selectedBasket == 3 ? 'bg-indigo-600 p-2 w-full text-white rounded-md' : 'bg-slate-400 p-2 w-full text-white rounded-md'} onClick={() => {basketHandel(3) }} >Sepet 4</button>

                        </div>

                    </div>

                    {
                        selectedBasket == 0 && <div className={basket.length > 0 ? 'absolute bg-slate-100 shadow-sm shadow-black/25 left-0 bottom-0  w-full flex items-center justify-between text-white rounded-md p-4 ' : 'hidden'}>
                            <span className='w-full flex items-center justify-start text-black'>
                                Toplam : {totalHandel()} TL
                            </span>
                            <button onClick={() => { sellHandel() }} className={basket.length > 0 ? 'bg-green-600 tracking-wide text-white rounded-md p-4 ' : 'hidden'}>Tamamla</button>
                        </div>
                    }
                    {
                        selectedBasket == 1 && <div className={b2.length > 0 ? 'absolute bg-slate-100 shadow-sm shadow-black/25 left-0 bottom-0  w-full flex items-center justify-between text-white rounded-md p-4 ' : 'hidden'}>
                            <span className='w-full flex items-center justify-start text-black'>
                                Toplam : {totalHandel()} TL
                            </span>
                            <button onClick={() => { sellHandel() }} className={b2.length > 0 ? 'bg-green-600 tracking-wide text-white rounded-md p-4 ' : 'hidden'}>Tamamla</button>
                        </div>
                    }
                    {
                        selectedBasket == 2 && <div className={b3.length > 0 ? 'absolute bg-slate-100 shadow-sm shadow-black/25 left-0 bottom-0  w-full flex items-center justify-between text-white rounded-md p-4 ' : 'hidden'}>
                            <span className='w-full flex items-center justify-start text-black'>
                                Toplam : {totalHandel()} TL
                            </span>
                            <button onClick={() => { sellHandel() }} className={b3.length > 0 ? 'bg-green-600 tracking-wide text-white rounded-md p-4 ' : 'hidden'}>Tamamla</button>
                        </div>
                    }
                    {
                        selectedBasket == 3 && <div className={b4.length > 0 ? 'absolute bg-slate-100 shadow-sm shadow-black/25 left-0 bottom-0  w-full flex items-center justify-between text-white rounded-md p-4 ' : 'hidden'}>
                            <span className='w-full flex items-center justify-start text-black'>
                                Toplam : {totalHandel()} TL
                            </span>
                            <button onClick={() => { sellHandel() }} className={b4.length > 0 ? 'bg-green-600 tracking-wide text-white rounded-md p-4 ' : 'hidden'}>Tamamla</button>
                        </div>
                    }
                    {
                        basket.length > 0 &&
                        <div ref={ref} className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center h-auto gap-4'>
                            {
                                selectedBasket == 0 && basket.map((item, index) => {
                                    return (
                                        <SalesCard item={item} func={setBasket} value={basket} key={item.barcode}></SalesCard>
                                    )
                                })
                            }

                        </div>
                    }
                    {
                        b2.length > 0 &&
                        <div ref={ref2} className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center h-auto gap-4'>
                            {
                                selectedBasket == 1 && b2.map((item, index) => {
                                    return (
                                        <SalesCard item={item} func={setB2} value={b2} key={item.barcode}></SalesCard>
                                    )
                                })
                            }

                        </div>
                    }
                    {
                        b3.length > 0 &&
                        <div ref={ref3} className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center h-auto gap-4'>
                            {
                                selectedBasket == 2 && b3.map((item, index) => {
                                    return (
                                        <SalesCard item={item} func={setB3} value={b3} key={item.barcode}></SalesCard>
                                    )
                                })
                            }

                        </div>
                    }
                    {
                        selectedBasket == 3 && b4.length > 0 &&
                        <div ref={ref4} className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center h-auto gap-4'>
                            {
                                selectedBasket == 3 && b4.map((item, index) => {
                                    return (
                                        <SalesCard item={item} func={setB4} value={b4} key={item.barcode}></SalesCard>
                                    )
                                })
                            }

                        </div>
                    }
                </div>
                {/* <div className='w-full h-screen grid border-l  border-black grid-cols-4 place-items-center  gap-4 overflow-y-scroll '>
                    {list && list.map((item, index) => {
                       
                        return (
                            <div key={index} onClick={() => { basketSet(item) }} className='w-40 border shadow-sm shadow-black/20 cursor-pointer rounded-md p-2 flex flex-col items-start justify-start'>
                                <img src={item.image} className='w-40'></img>
                                <p className='text-black'>
                                    {item.name}
                                </p>
                            </div>
                        )
                    })}
                </div> */}
            </div>


        </Container>
    )
}

export default Sales