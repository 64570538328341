import React from 'react'
import Container from '../../Components/Container'
import { useState, useEffect } from 'react'
import { Chart } from "react-google-charts";


const Statistics = () => {
    const url = 'https://barcode.batuhanciftci.net/server/statistics.php'
    const [max, setMax] = useState([])
    const [min, setMin] = useState([])
    const [_float, setFloat] = useState([])
    const [_kar, setKar] = useState([])
    const convertDataForChart = (data) => {
        const chartData = [["Title", "Kar"]];
        data.forEach(item => {
            chartData.push([item.title, item.kar]);
        });
        return chartData;
    };
    function convertToBarChartData(data) {
        // Başlık satırını oluşturuyoruz
        const chartData = [
            ['Yıllık işlem sayıları', 'İade', 'Satış', 'Zaıat']
        ];

        // Verileri dönüştürüyoruz
        data.forEach((item, index) => {
            const { ıade, satıs, zaıat } = item[0]; // Verilerin içindeki ilk objeyi alıyoruz
            chartData.push([`ay ${index + 1}`, ıade, satıs, zaıat]);
        });

        return chartData;
    }
    function convertToBarChartData2(data) {
        const chartData = [
            ['Aylar', 'Kâr', 'Satış', 'Maliyet'] // Başlıklar
        ];

        data.forEach((item, index) => {
            const { kar, satıs, maliyet } = item[0];
            // Eğer değerler null ise bunları 0 yapıyoruz
            chartData.push([`Ay ${index + 1}`, kar || 0, satıs || 0, maliyet || 0]);
        });

        return chartData;
    }
    useEffect(() => {

        const formdata = new FormData()
        formdata.append('action', 'max')
        fetch(process.env.REACT_APP_BASE_URL + 'statistics.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                setMax(data)
            })


        const formdata2 = new FormData()
        formdata2.append('action', 'min')
        fetch(process.env.REACT_APP_BASE_URL + 'statistics.php', {
            method: 'POST',
            body: formdata2
        }).then(res => res.json())
            .then(data => {
                setMin(data)
            })
        const formdata3 = new FormData()
        formdata3.append('action', 'float')
        formdata3.append('date', new Date().getFullYear())
        fetch(process.env.REACT_APP_BASE_URL + 'statistics.php', {
            method: 'POST',
            body: formdata3
        }).then(res => res.json())
            .then(data => {
                setFloat(data)
                console.log(convertToBarChartData(data))
            })
        const formdata4 = new FormData()
        formdata4.append('action', 'kar')
        formdata4.append('date', new Date().getFullYear())
        fetch(process.env.REACT_APP_BASE_URL + 'statistics.php', {
            method: 'POST',
            body: formdata4
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                setKar(data)
            })
    }, [])


    return (
        <Container>

            <div className='w-full  shadow-black/50 shadow-md  rounded-md  h-auto flex flex-col lg:flex-row items-center justify-center gap-4'>
                <div className='w-full flex  h-full p-2'>
                    <Chart
                        chartType="PieChart"
                        data={convertDataForChart(max)}
                        options={{
                            title: "En Karlı 10 Ürün",
                        }}
                        width={"100%"}
                        height={"400px"}
                    />

                </div>
                <div className='lg:h-60 h-px lg:w-px w-10/12  bg-black/50'></div>
                <div className='w-full flex h-full p-2'>
                    <Chart
                        chartType="PieChart"
                        data={convertDataForChart(min)}
                        options={{
                            title: "En Karsız 10 Ürün",
                            animation: {
                                duration: 1000,
                                easing: "out",
                                startup: true,
                            }
                        }}
                        width={"100%"}
                        height={"400px"}
                    />
                </div>

            </div>
            <div className='w-full  shadow-black/50 shadow-md  rounded-md  h-auto flex items-center justify-center p-4'>
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={convertToBarChartData(_float)}
                    options={{
                        title: "Aylık işlem sayıları",
                        vAxis: {
                            minValue: 0,
                            max: 10000
                        },
                        animation: {
                            duration: 300,
                            easing: 'out',
                            startup: true
                        }
                    }}
                />
            </div>
            <div className='w-full  shadow-black/50 shadow-md  rounded-md  h-auto flex items-center justify-center p-4'>
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={convertToBarChartData2(_kar)}
                    options={{
                        title: 'Kâr, Satış ve Maliyet',
                        chartArea: { width: '50%' },
                        hAxis: {
                            title: 'Toplam',
                            minValue: 0,
                            format: 'decimal',  // Formatı tam sayıya çektik
                        },
                        bars: 'horizontal',  // Yatay çubuklar
                        height: 400,
                        width: '100%',
                        bar: { groupWidth: '75%' },  // Çubuk genişliği için ayarlama
                        // vAxis kaldırıldı
                    }}
                />
            </div>
        </Container>
    )
}

export default Statistics