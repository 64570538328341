import Container from '../../Components/Container'
import ProductCard from '../../Components/ProductCard'
import { Link } from 'react-router-dom'
import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import toast, { Toaster } from 'react-hot-toast';
import { BarcodeScanner, useTorch } from "react-barcode-scanner"
import { FaCamera } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom'
import MultiRangeSlider from "multi-range-slider-react";
import Test from './Test'





const Product = () => {

  const [items, setItems] = useState([])
  const [deletedItems, setDeletedItems] = useState([])
  const [deleteItem, setDeleteItem] = useState(false)
  const [barcode, setBarcode] = useState()
  const [name, setName] = useState(null)
  const [isSupportTorch, , onTorchSwitch] = useTorch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const webcamRef = useRef(null);
  const [page, setPage] = useState(1);
  const observer = useRef();
  const navigate = useNavigate()
  const filterContainer = useRef()
  const rangeText = useRef()
  const [minValue, set_minValue] = useState(1);
  const [maxValue, set_maxValue] = useState(10000);
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    rangeText.current.innerText = `${minValue} - ${maxValue}`
  };




  const fetchData = () => {
    const formdata = new FormData()
    formdata.append('action', 'selectWithPage')
    formdata.append('page', page)
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    })
      .then(res => res.json())
      .then(data => {
        setItems([...items, ...data])
      })
  }
  const lastCarElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading]);

  useEffect(() => {
    fetchData()

  }, [page])

  useEffect(() => {

    window.localStorage.setItem('count', 1)
    fetchData()
    const formdata2 = new FormData()
    formdata2.append('action', 'selectDeleted')
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata2
    })
      .then(res => res.json())
      .then(data => {
        setDeletedItems(data)
      })



  }, []

  )

  const linkHandel = () => {

    window.localStorage.setItem('count', 30);
    navigate('/dashboard/addProduct')

  }

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
      return;
    }
    setPage(prevPage => prevPage + 1);
  }, [loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);



  const options = {

    formats: [
      'ean_13',
      'code_128',
      'code_39',
      'code_93',
      'codabar',
      'ean_8',
      'itf',
      'qr_code',
      'upc_a',
      'upc_e',
    ]
  }


  const onCapture = (detected) => {
    if (detected) {
      const formdata = new FormData()
      formdata.append('action', 'findByBarcode')
      formdata.append('barcode', detected.rawValue)
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json()).then(data => {
        if (data.status != 404) {
          setItems([data.data])
          setOpen(false)
        }
        else {
          alert('Ürün bulunamadı')
        }
      })



    }
  }

  const filterByname = () => {

    const formdata = new FormData()
    formdata.append('action', 'findByName')
    formdata.append('name', name == null ? 'a' : name)
    formdata.append('min', minValue)
    formdata.append('max', maxValue)
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    })
      .then(res => res.json())
      .then(data => {
        if (data.status != 200) {
          toast.error(data.message)
        } else {
          setItems(data.data)
          filterHandel()
        }
      })

  }

  const deleteAll = () => {
    const formdata = new FormData()
    formdata.append('action', 'deletePermaAll')
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
      .then(data => {
        if (data.status != 200) {
          toast.error(data.message)
        } else {
          toast.success(data.message)
          setTimeout(() => {
            window.location.reload()
          }, 500)


        }
      })
      .finally()
      .catch(e => console.error(e))
  }


  const filterHandel = () => {

    filterContainer.current.classList.toggle('hidden')

  }


  const keyHandel = (e) => {

    if (e.key === 'Enter') {
      const formdata = new FormData()
      formdata.append('action', 'findByBarcode')
      formdata.append('barcode', barcode)
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json()).then(data => {
        if (data.status != 404) {

          setItems([data.data])
        } else {
          toast.error(data.message)
        }
      })
    }

  }


  return (
    <Container>
      <Toaster position='top-center'></Toaster>
      
      <div className='w-full flex items-center justify-around gap-5'>
        {
          open && <div onClick={(e) => { }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
            <div className='bg-white rounded-md lg p-4 lg:h-full w-full  gap-6 flex items-start justify-start flex-col'>
              <BarcodeScanner options={options} onCapture={onCapture} />
              <div className='w-full flex items-center gap-2 justify-between'>

                {isSupportTorch ? (
                  <button className='bg-yellow-400 whitespace-nowrap text-black px-4 py-2 rounded-md' onClick={onTorchSwitch}>El Feneri</button>
                ) : null}
                {barcode}
                <button onClick={() => { setOpen(false) }} className='bg-red-500 w-full flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
                  Kapat
                </button>
              </div>
            </div>
          </div>
        }
        <div className='w-full flex items-start justify-center flex-col gap-3'>
          {
            !deleteItem ? <h1 className='text-2xl font-semibold text-gray-800'>Ürünler</h1> : <h1 className='text-xl whitespace-nowrap font-semibold text-gray-800'>Silinen Ürünler</h1>
          }
          {
            !deleteItem ? <span className='text-gray-500 whitespace-nowrap'>Toplam {items.length} Ürün</span> : <span className='text-gray-500 whitespace-nowrap'>Toplam {deletedItems.length} Silinen Ürün</span>
          }
        </div>
        <div className='w-full flex items-end justify-center gap-3 flex-col'>
          <span className='w-full flex items-center justify-end gap-4'>
            <span className='text-gray-500 whitespace-nowrap'>Silinenleri Göster</span>
            <Switch
              checked={deleteItem}
              onChange={setDeleteItem}
              className={`${deleteItem ? 'bg-indigo-400' : 'bg-gray-200'
                } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span className='sr-only'>Enable notifications</span>
              <span
                className={`${deleteItem ? 'translate-x-6' : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
          </span>
          <span className='w-full flex items-center justify-end gap-4'>
            {
              deleteItem ? <button onClick={() => deleteAll()} className='w-full bg-red-600 text-white whitespace-nowrap rounded-md  px-4 py-2'>Hepsini Sil</button> : null
            }
            <button onClick={() => linkHandel()} className='bg-indigo-400 whitespace-nowrap text-white px-4 py-2 rounded-md'>30X</button>
            <Link to={'/dashboard/addProduct'} className='bg-indigo-400 whitespace-nowrap text-white px-4 py-2 rounded-md'>Ürün Ekle</Link>
          </span>

        </div>
      </div>
      <div className='w-full flex items-center gap-2 justify-between'>
        <div className='w-full flex flex-col items-center justify-center gap-4'>
          <input type='text' onKeyDown={(e) => { keyHandel(e) }} value={barcode} onChange={(e) => { setBarcode(e.target.value) }} placeholder='Barkod giriniz' className='w-full p-2 border border-gray-300 rounded-md' />
          <button onClick={() => filterHandel()} className='bg-green-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap w-full h-10 px-4'>Filtrele </button>
          <div ref={filterContainer} className='fixed hidden left-0 gap-4 top-0 h-screen w-full flex items-center justify-center flex-col p-6 bg-slate-200'>

            <input type='text' onKeyDown={(e) => { }} value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Ürün adı giriniz' className='w-full p-2 border border-gray-300 rounded-md' />
            <span className='w-full bg-white border border-gray-300 gap-6 px-6 flex items-center rounded-md justify-between'>
              <label ref={rangeText} className=' whitespace-nowrap text-slate-400'>Fiyat</label>
              <MultiRangeSlider
                id='range'
                className='w-full bg-white !rounded-md !border-none  !shadow-none'
                min={0}
                max={maxValue}
                step={1}
                minValue={minValue}
                maxValue={maxValue}
                onInput={(e) => {
                  handleInput(e);
                }}
                ruler={false}
                barInnerColor='black'
              />
            </span>
            <div className='w-full flex items-center justify-center gap-12'>
              <button onClick={() => { filterByname() }} className='bg-green-500 w-full flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
                Ara
              </button>
              <button onClick={() => { filterHandel() }} className='bg-red-500 w-full flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
                Kapat
              </button>
            </div>
          </div>
        </div>
        <button onClick={() => { setOpen(true) }} className='bg-indigo-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-24 px-4'>
          <FaCamera />
          <p className='w-0 group-hover:w-32 overflow-hidden transition-all'>
            Kamera ile oku
          </p>
        </button>
      </div>

      {
        deleteItem ?
          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
            {
              deletedItems.map((item) => {
                return (
                  <ProductCard item={item} key={item.id} />
                )
              })
            }
          </div>
          :
          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
            {
              items.map((item) => {
                return (
                  <ProductCard item={item} key={item.id} />
                )
              })
            }
          </div>
      }
      <span ref={lastCarElementRef}></span>

    </Container>
  )
}

export default Product